import React, { useState } from 'react'
import logotext from '../../assets/logo-text.png'
import faqlogo from '../../assets/faq.png'
import MintImage from '../../assets/mint.png'
import BuyImage from '../../assets/buy.png'
import CreateImage from '../../assets/create.png'
import BidImage from '../../assets/bid.png'
import TransferImage from '../../assets/transfer.png'
import BuyPizzaImage from '../../assets/buy-pizza.png'
import DesktopPan from '../../assets/desktop-pan.png'
import MobilePan from '../../assets/mobile-pan.png'


const StarParalax = () => {


    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <img src={logotext} alt="" className="img-responsive" />
                                        <img src={faqlogo} alt="" className="img-responsive" />
                                    </div>
                                    <div className="hero-games">
                                        <h2><img src={MintImage} alt="" /> How to Mint?</h2>
                                        <ol>
                                            <li>You Must Have $PIZZA native tokens to mint.  More details to Buy the native tokens, $PIZZA; Go to section below for help.  Buy $PIZZA tokens.</li>
                                            <li>Click on Create button on main home page, you will be redirected to Create page.</li>
                                            <li>Click on Category and a pull down menu will appear.  Pick your choices [art, photography, sports, athletes, celebrities, music, gif and videos, collectibles, trading cards, utilities, virtual worlds]</li>
                                            <li>Click on Chose File in the Upload File section.  Now select the file from your PC or mobile. This is the file you want to mint or sell.</li>
                                            <li>Input Item Name</li>
                                            <li>Input the Description</li>
                                            <li>Input the Item Price in BNB (Binance Smart Chain) value</li>
                                            <li>Input Royalty Fees if you want to charge Royalty for future sales of your original mint.  Max input for Royalty Fees is up to 20%.  Royalty Fees are 80/20 split for future sales. 80% goes back to original Minter Owner.</li>
                                            <li>Input the size of file. Click on arrow down for pull down menu.  For pixel size, you can check your file properties and input example 100px x 120px, any size you have for your digital file.  Input the mb/g size of your file, example 200mb or 1gig.  This is not a required section if you are having trouble finding your file size.</li>
                                            <li>Select Type of listing</li>
                                            <ul>
                                                <li>Bid Price for Auction</li>
                                                <li>Buy Now Price</li>
                                                <li>Mint Only (this is not for sale and just for minting your collection, later you can put on sale).  Or this is for purpose of recording any document you want to have record of ownership. Many companies are minting their legal public documents on the blockchain to have official ownership records that cannot be altered.</li>
                                            </ul>
                                            <li>Click CREATE ITEM</li>
                                            <li>Approve the Selling Price in BNBs and $PIZZA (PIZZANFT TOKEN) fee for minting, from your connected wallet</li>
                                            <li>Wait 10minutes for Your mint to appear on the Binance Block Chain.  This is BSC Blockchain’s variable time range.</li>
                                            <li>Congratulations you have now Minted your File For Minting or Selling on PizzaNFT Marketplace.</li>
                                        </ol>
                                        <h2><img src={CreateImage} alt="" /> How to Create Auction?</h2>
                                        <ol>
                                            <li>Click on the Item you own.</li>
                                            <li>Click on Create Auction button.</li>
                                            <li>Enter the Bid Start price in BNB (Binance Smart Chain). This will be the starting price of the item's auction.</li>
                                            <li>Click on Create button.</li>
                                            <li>Approve the Transaction from your connected wallet.</li>
                                            <li>You can choose to Sell to the Highest bidder at the end of auction.</li>
                                        </ol>
                                        <h2><img src={BuyImage} alt="" /> How to Buy?</h2>
                                        <ol>
                                            <li>Click on the Item you want to Buy, you'll be redirected to the Art page.</li>
                                            <li>Click on Buy It.</li>
                                            <li>Approve the BNB price and transaction fees from your connected wallet.</li>
                                        </ol>
                                        <h2><img src={BidImage} alt="" /> How To Bid?</h2>
                                        <ol>
                                            <li>Click on the Item in Auction you want to Bid, you will be redirected to Auction page.</li>
                                            <li>Click in Place a Bid.</li>
                                            <li>Enter the BNB (Binance smart chain) amount you want to Bid and Click on Make Bid.</li>
                                            <li>Approve the Bid from your wallet.</li>
                                            <li>Item will be sold to Highest bidder at the end of auction.</li>
                                        </ol>
                                        <h2><img src={TransferImage} alt="" /> How to Transfer?</h2>
                                        <ol>
                                            <li>Click on the Item you own.</li>
                                            <li>Click on Transfer button.</li>
                                            <li>Enter the recipient wallet address.</li>
                                            <li>Click on Send.</li>
                                            <li>Approve the Transfer from your connected wallet.</li>
                                        </ol>
                                        <h2><img src={BuyPizzaImage} className="buy-image-pizza" alt="" /></h2>
                                        <ol>
                                            <li>Buy $PIZZA native tokens from Pancake Swap</li>
                                            <li>Can watch this Video on How to Buy $PIZZA native tokens, on the Pizza NFT Youtube Channel
                                                <a href="https://www.youtube.com/watch?v=udnvTByStEk&t=16s" target="_blank">Link here</a></li>
                                            <li>Below is the Link to Buy $PIZZA native token, from Pancake Swap. Can wallet connect to Metamask or Trust Wallet app to buy any BEP20 token (Binance Smart Chain tokens)
                                                PizzaNFT Smart Contract is:  0xb07905396A419B121213efe1d17cfD0ff20aE597
                                            </li>
                                            <li>Click on the Upper BUY button (from homepage of PizzaNFT.studio) to redirect you to Pancake Swap to Buy $PIZZA native tokens.  <a href="https://pancakeswap.finance/swap?outputCurrency=0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank">This is the domain link</a></li>
                                            <li>The PC version will show this screen.
                                                <img src={DesktopPan} alt="" className='img-responsive' />
                                            </li>
                                            <li>The mobile version will show
                                                <img src={MobilePan} alt="" className='img-responsive' />
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarParalax
